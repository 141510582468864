.nav-link{

    font-size: 16px !important;
    padding-left: 15px !important;
    color:white !important;
    text-transform: capitalize !important;


}
.navbar {
    position: absolute !important;
    top: 0px !important;
    width: 100% !important;
  }

.phoneButton{
        /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(110 80 20 / 40%), inset 0 -2px 5px 1px rgb(139 66 8), inset 0 -1px 1px 3px rgb(250 227 133); */
    background-image: linear-gradient(90deg, #ae8625, #f7ef8a, #d2ac47, #edc967);
    border: 1px solid #a55d07 !important;
    color: rgb(120,50,5) !important;
    text-shadow: 0 2px 2px rgb(250 227 133) !important;
    cursor: pointer !important;
    transition: all .2s ease-in-out !important;
    border-radius: 20px !important;
    line-height: 35px !important;
    height: 40px !important;
    font-size: 16px !important;
    color: #000 !important;
    padding: 0px 1.2em !important;
    font-weight: 400 !important;
    border: none !important;
    margin-left:10px !important ;

}

@media screen and (max-width:992px){
    .phoneButton {
        margin-top:20px !important ;
    }

}