.whatsapp{
    color: #fff;
    background-color:#0ca678;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 6px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    left: 20px;
    cursor: pointer;
}
.icon{
    font-size:50px;
}
.whatsapp:hover{
    background-color:#087051;
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .whatsapp{
        position: fixed;
        bottom: 30px;
        right: 10px;
        cursor: pointer;
        left: 20px;

    }
    .icon{
        font-size:40px;
    }
}
@media screen and (max-width:408px) {
    .whatsapp{
        bottom: 15px;
    }

}