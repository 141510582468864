.bgImage{
    padding: 0;
    background-image:url('../../assets/hero-banner.png');
   /*  background-size:auto;
    background-repeat: no-repeat;
    background-color: #000;
    border-top: 1px solid #636363;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-color: #000; */
        background-size: auto 100%;
    /* min-height: 100vh; */
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-color: #000;


    padding: 150px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.bgImage h2{
    text-align: center;
    line-height: 50px;
    font-weight: 400;
    font-size: 40px;
    text-transform: capitalize;
    background: -webkit-linear-gradient(
      12deg,
      rgb(174, 134, 37) 0%,
      rgb(247, 239, 138) 42%,
      rgb(210, 172, 71) 79%,
      rgb(237, 201, 103) 100%
    );
  
    padding: 0 0 15px 0;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(247, 239, 138);
    margin: 0 auto !important;
    font-weight: 400;
    color: white !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(174, 134, 37);
    display: inline-block;
    margin: auto;
    
}
.bgImage p{
      
      color: rgb(255 251 187) ;
      margin: 0 auto !important;
      font-size: 14px;
      width: 50%;
        /* Adjust width to be wider than h2 by 20px */

}

@media screen and (max-width:500px) {
  .bgImage p{
      width:280px;
  }
}
@media screen and (max-width:350px) {
  .bgImage h2{
    font-size: 36px;
    line-height: 38px;
    width:200px;
  }
}