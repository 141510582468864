.card-container{
    color: #ccc;
}


.card-container h1{
    font-size: 32px;
}
.card-container p{
    font-weight: 400;
    font-size: 13px;    
}
.card-container button{
    margin-top: 20px;
    color: #dbdbdb ;
    padding: 0px;
    border-radius: 25px ;
    perspective: 1px !important;
    border-collapse: separate !important; 
    overflow: visible;
    width: 151px;
    height: 40px;
    border: 1px solid transparent;
    font-size: 16pt;
    outline: none;
    font-size: 16px;
    text-align: center;
    transition: all 0.2s linear;
    font-weight: bold;
    background: linear-gradient(#000,#000) content-box,
    linear-gradient(12deg,rgb(174, 134, 37) 0%,rgb(247, 239, 138) 42%,rgb(210, 172, 71) 79%,rgb(237, 201, 103) 100%) border-box !important ;
    font-weight: 400 !important;
  }
.card-container img{
    max-width: 75%;
    display: block; /* This makes sure margin-left and margin-right are effective */
    margin-left: auto;
    margin-right: auto;
    
}
 .section-text{
    max-width: 80%;
    display: block; /* This makes sure margin-left and margin-right are effective */
    margin-left: auto;
    margin-right: auto;
}
.logo-list{
    padding: 0 0 30px 0;
    list-style: none;
    color: #d2d2d2 !important;
    font-size: 14px !important;
    font-family: 'Work Sans' !important;
}
.logo-list li:hover{
    cursor: pointer;
}
.iconStyle{
    color:rgb(210, 172, 71) ;
    padding: 0 10px 0 0 !important;
    font-weight: 900 !important;
    
}

.dark{
    background-color: #101010;
    padding: 0;
}

.light{
    background-color: #1e1e1e;
}

@media screen and (max-width:992px){
    .card-container img{
        max-width: 100%;
    }
     .section-text{
        max-width: 100%;
    }
    .logo-list{
        padding: 0 0 15px 0;
    }
}
@media screen and (max-width:860px){
    .card-container h1{
        font-size: 24px;
    }
    .card-container p{
        font-weight: 400;
        font-size: 12px;    
    }
    .logo-list{
        padding: 0 0 0 0;
    }

}