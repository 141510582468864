@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;600&display=swap");
* {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: "Work Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #dbdbdb;
}

code {
  font-family: "Work Sans" !important;
}
.bgColor {
  background-color: #000;
}
.nav-link.active {
  color: #f7ef8a !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 30px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 2px 2px rgb(250 227 133);
  border-radius: 10px;
  height: 30px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(90deg, #ae8625, #f7ef8a, #d2ac47, #edc967);
  border-radius: 10px;
  height: 30px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(90deg, #ae8625, #f7ef8a, #d2ac47, #edc967);
  border: 1px solid #a55d07 !important;
  height: 30px !important;
}
@media screen and (max-width: 992px) {
  .offcanvas {
    background-color: #101010 !important;
  }
  .offcanvas-header {
    padding: 1rem 1rem 1rem 2rem !important;
  }
  .nav-link {
    color: #fff !important;
  }
  .navbar-dark .navbar-nav .nav-link.active {
    color: #f7ef8a !important;
    font-weight: 400 !important;
    text-decoration: none !important;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: #f7ef8a !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #f7ef8a !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  font-size: 17px !important;
  transition: 1ms;
}

.dropdown-menu-dark {
  background: url("./assets/bg-body1.jpg") !important;
}
.centered {
  margin: 3rem auto !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  min-height: 100vh;
}
.is-invalid:hover {
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none !important;
}

.modal-header {
  border: none !important;
}
input {
  color-scheme: dark;
}

/* ------------------ */
.info-wrap {
}
.info-wrap h3 {
  color: white;
  font-size: 18px;
  position: relative;
}
.info-wrap .dbox {
  width: 100%;
  margin-bottom: 25px;
}
.info-wrap .dbox:last-child {
  margin-bottom: 0;
}
.info-wrap .dbox p {
  margin-bottom: 0;
    color: #dbdbdb;
  font-weight: 200;
}
.info-wrap .dbox p span {
  font-weight: 400;
  color: #fff;
}
.info-wrap .dbox p a {
  color: #dbdbdb;
  font-weight: 200;
}
.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.02);
}
.info-wrap .dbox .icon span {
  font-size: 20px;
  color: #fff;
}
.info-wrap .dbox .text {
  width: calc(100% - 50px);
}
