
.portfolioText {
  text-align: center;
}

.portfolioText h6 {
  color: #ae8625;
  display: block;
  font-weight: normal;
}

.portfolioText h2 {
  color: #ffffff;
  padding: 0 0 10px 0;
  font-size: 45px;
  line-height: 55px;
  font-family: "Work Sans";
  font-weight: lighter;
}


.section{
    color: #fff;
}
.section h1{
    font-size: 36px;
    text-transform: capitalize;
}
.section h1 span{
    color: rgb(210, 172, 71);
}
.section p{
    font-size: 14px;
}
.section .main-img{
    display: block; /* This makes sure margin-left and margin-right are effective */
    margin-left: auto;
    margin-right: auto;
}
.light-opacity-text{
        font-size: 18px;
    line-height: 160%;
    color: rgba(255, 255, 255, .6);
}
.metric-card{
    border-color: rgb(210, 172, 71) !important;
    padding-top: 40px;
}

.metric-card h3{
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 40px;
    font-weight: 500;
    line-height: 120%;
}
.overview-section{
    background-color: #000;
    color: #fff;
}
.overview-section h1{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1.25em;
}
.overview-section h2{
        font-size: 56px;
    font-weight: 800;
    white-space: pre-wrap;
    margin-bottom: 0;
}

.overview-section span{
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -0.25px;
}
@media only screen and (max-width: 768px) {
    .overview-section h1{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1.25em;
    }
    .overview-section h2{
        font-size: 30px;
    font-weight: 800;
    white-space: pre-wrap;
    margin-bottom: 0;
    }

    .overview-section span{
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.25px;
    }
    
}
@media only screen and (max-width: 668px) {
    .overview-section h1{
        display:none;
    }
    .overview-section h2{
        font-size: 20px;
    font-weight: 800;
    white-space: pre-wrap;
    margin-bottom: 0;
    }

    .overview-section span{
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.25px;
    }
    
}