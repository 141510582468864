
.Modal{
    background: rgb(32,65,77);
    background: linear-gradient(90deg, rgba(32,65,77,1) 0%, rgba(35,52,72,1) 30%, rgba(36,36,76,1) 46%, rgba(25,27,52,1) 100%);    
    border:none ;
    padding: 0px !important;
    margin: 0!important;
    border-radius: 15px;
    color: #46E474 ;

}
.ModalDanger{
    background: #451D46;
    background: linear-gradient(90deg, #451D46 0%, rgba(35,52,72,1) 30%, rgba(36,36,76,1) 46%, rgba(25,27,52,1) 100%);    
    border:none  !important;
    padding: 0px !important;
    margin: 0!important;
    border-radius: 15px;
    color: #E21348;
}
.ModalHead{
    border:none ;
    padding-bottom: 0px !important;
    margin-bottom: 0!important;
    
}
.ModalBody{
    border:none ;
    padding-top: 0px !important;
    margin-top: 0!important;
}
.ModalBody p{
    color: #fff;
    font-size: 12px;
    padding: 0 !important;
    margin: 0 !important;
}
.ModalText{

    font-size: 18px;
    padding: 0 !important;
    margin: 0 !important;
}
.iconSuccess{
    font-size: 28px !important;
    color: #46E474 !important;
}
.Modelheading{
        color: #46E474 !important;
}
